body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* { padding: 0; margin: 0; }
html {
    height: 100%;
    margin: 0;
    position: relative;
    min-height: 100% !important;
    display: block;
    overflow: auto;
    background: black;
}
body {
    position: relative;
    height: 100%;
    margin: 0;
}


@font-face {
  font-family: 'Justice';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/justice.ttf');
}

@font-face {
  font-family: 'JusticeSolid';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/justiceSolid.ttf');
}

@font-face {
  font-family: 'Gibson';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Gibson-Bold.ttf');
}

@font-face {
  font-family: 'GibsonLight';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Gibson-Regular.ttf');
}

@font-face {
  font-family: 'Lunasol';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/LunasolAurora-Regular.ttf');
}

@font-face {
  font-family: 'Canossa';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Pressboard.ttf');
}

@font-face {
  font-family: 'PaintPeel';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/PaintPeel.ttf');
}