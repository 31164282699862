.App {
  height: 100%;
  width: 100%;
  text-align: center;
  background: black;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


@media only screen {
  .hvr-sink {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  .hvr-sink:hover {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
}

/* Bob */
@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
}

@keyframes hvr-colors {
  0% {
    color: linear-gradient(red, blue);
  }

  100% {
    color: linear-gradient(blue, red);
  }
}

.hvr-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-bob:hover {
  -webkit-animation-name: hvr-bob-float, hvr-bob;
  animation-name: hvr-bob-float, hvr-bob, hvr-colors;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

.hvr-bob:focus {
  animation: none;
}

.neon-skies-image { 
  width: 70px;
}
.neon-skies-image:hover { 
  opacity: .7;
}

.homepage {
  width: 100%;
  align-items: center;
  position: relative;
  min-height: 100%;
  display: block;
  background: linear-gradient(#323440, black);
}

.link-color {
  color: white;
}

.link-color:hover {
  color: lightgray;
}

.gold-heart-logo-image {
  width: 300px;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.gold-heart-wipe:hover {
  text-align: center;
  font-family: JusticeSolid;
  
  background: linear-gradient(to right, #D4AC58 0%, transparent 10%, transparent 40%, transparent 60%, transparent 90%, #D4AC58 100%);
  background-size: 200% auto;
  
  color: #D4AC58;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1s linear infinite;
  animation-direction: reverse;
}

.neon-skies-wipe {
  text-align: center;
  font-family: JusticeSolid;
  background: linear-gradient(to right, rgba(256,256,256, .21) 0%, rgba(255, 142, 255, .21), rgba(255, 255, 29, .21), rgba(255, 255, 29, .21), rgba(43, 253, 254, .21), rgba(94, 255, 123, 0.21), rgba(256,256,256, .21) 90%);
  background-size: 200% auto;
  animation: shine 5s linear infinite;
  animation-direction: reverse;
}

.gold-heart-text-outline {
  font-family: Justice;
}

.gold-heart-text-solid {
  font-family: JusticeSolid;
}

.gold-heart-text-outline:hover {
  font-family: Justice;
  text-decoration: none;
}

.gold-heart-text-solid:hover {
  font-family: JusticeSolid;
  text-decoration: none;
}

.justice-solid {
  font-family: JusticeSolid;
}

.lunasol {
  font-family: Lunasol;
}

.neon-white:hover {
  color: white;
}

.inner-style {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-items: center;
}